var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.index)?_c('b-container',{staticClass:"CollectionList"},[_c('headline',[_c('div',{staticClass:"d-flex flex-row"},[_c('span',{staticClass:"flex-grow-1 text-truncate"},[_c('i',{staticClass:"fa fa-database text-secondary"}),_vm._v(" "),_c('span',{staticClass:"code"},[_vm._v(_vm._s(_vm.indexName))])]),_c('span',{staticClass:"text-right"},[_c('b-button',{staticClass:"align-middle",attrs:{"data-cy":"CollectionList-create","variant":"primary","disabled":!_vm.canCreateCollection(_vm.indexName) || !_vm.index,"title":!_vm.canCreateCollection(_vm.indexName)
                ? ("Your rights disallow you to create collections on index " + _vm.indexName)
                : '',"to":{ name: 'CreateCollection', params: { indexName: _vm.indexName } }}},[_c('i',{staticClass:"fa fa-plus"}),_vm._v(" Create a collection ")]),_c('IndexDropdownAction',{attrs:{"indexName":_vm.indexName},on:{"delete-index-clicked":_vm.onDeleteIndexClicked}})],1)])]),(!_vm.canSearchCollection(_vm.indexName))?_c('list-not-allowed'):(_vm.collections)?_c('div',{staticClass:"CollectionList-content"},[[_c('b-row',{staticClass:"mb-3"},[_c('b-col',{staticClass:"text-secondary",attrs:{"sm":"2"}},[_vm._v(" "+_vm._s(_vm.collections.length)+" "+_vm._s(_vm.collections.length === 1 ? 'collection' : 'collections')+" ")]),_c('b-col',{attrs:{"sm":"10"}},[_c('b-row',[_c('b-col',{staticClass:"text-right",attrs:{"cols":"6"}},[_c('b-button',{staticClass:"mr-2",attrs:{"variant":"outline-dark"},on:{"click":_vm.onToggleAllClicked}},[_c('i',{class:("far " + (_vm.selectedCollections.length ===
                        _vm.filteredCollections.length
                          ? 'fa-check-square'
                          : 'fa-square') + " left")}),_vm._v(" Toggle all ")]),(
                    _vm.$store.direct.getters.kuzzle.currentEnvironment
                      .backendMajorVersion !== 1
                  )?_c('b-button',{attrs:{"variant":"outline-danger","data-cy":"CollectionList-bulkDelete--btn","disabled":!_vm.bulkDeleteEnabled},on:{"click":_vm.deleteCollections}},[_c('i',{staticClass:"fa fa-minus-circle left"}),_vm._v(" Delete ")]):_vm._e()],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-input-group',{scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('b-input-group-text',[_vm._v("Filter")])]},proxy:true}],null,false,3497745577)},[_c('b-form-input',{attrs:{"autofocus":"","debounce":"300","disabled":_vm.collections.length === 0},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.navigateToCollection($event)}},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}})],1)],1)],1)],1)],1),_c('b-table',{attrs:{"striped":"","outlined":"","show-empty":"","data-cy":"CollectionList-table","items":_vm.collections,"fields":_vm.tableFields,"filter":_vm.filter},on:{"filtered":_vm.updateFilteredCollections},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('h4',{staticClass:"text-secondary text-center"},[_vm._v(" This index has no collections. ")]),(_vm.canCreateCollection(_vm.index.name))?_c('p',{staticClass:"text-secondary text-center"},[_vm._v(" You can create the collection by hitting the button above. ")]):_vm._e()]},proxy:true},{key:"emptyfiltered",fn:function(){return [_c('h4',{staticClass:"text-secondary text-center"},[_vm._v(" There is no collection matching your filter. ")])]},proxy:true},{key:"cell(selected)",fn:function(row){return [_c('b-form-checkbox',{staticClass:"d-inline-block align-middle",attrs:{"type":"checkbox","unchecked-value":"false","value":"true","data-cy":("CollectionList-checkbox--" + (row.item.name)),"checked":_vm.isChecked(row.item)},on:{"change":function($event){return _vm.onCheckboxClick(row.item)}}})]}},{key:"cell(type)",fn:function(row){return [_c('i',{staticClass:"fa fa-2x",class:{
                'fa-bolt ml-2': row.item.type === 'realtime',
                'fa-th-list': row.item.type === 'stored'
              },attrs:{"title":row.item.type === 'realtime' ? 'Realtime' : 'Stored'}})]}},{key:"cell(name)",fn:function(row){return [_c('b-link',{staticClass:"code",attrs:{"data-cy":("CollectionList-name--" + (row.item.name)),"title":row.item.name,"to":row.item.type === 'realtime'
                  ? {
                      name: 'WatchCollection',
                      params: {
                        indexName: _vm.indexName,
                        collectionName: row.item.name
                      }
                    }
                  : {
                      name: 'DocumentList',
                      params: {
                        indexName: _vm.indexName,
                        collectionName: row.item.name
                      }
                    }}},[_vm._v(_vm._s(_vm.truncateName(row.item.name)))])]}},{key:"cell(count)",fn:function(row){return [_vm._v(" "+_vm._s(row.item.count)+" ")]}},{key:"cell(actions)",fn:function(row){return [_c('b-button',{staticClass:"mx-1",attrs:{"variant":"link","title":"Browse contents","to":row.item.type === 'realtime'
                  ? {
                      name: 'WatchCollection',
                      params: {
                        indexName: _vm.indexName,
                        collectionName: row.item.name
                      }
                    }
                  : {
                      name: 'DocumentList',
                      params: {
                        indexName: _vm.indexName,
                        collectionName: row.item.name
                      }
                    }}},[_c('i',{staticClass:"fa fa-eye"})]),_c('b-button',{staticClass:"mx-1",attrs:{"variant":"link","title":"Edit collection","data-cy":("CollectionList-edit--" + (row.item.name)),"disabled":row.item.type !== 'stored' ||
                  !_vm.canEditCollection(row.item.name),"to":_vm.canEditCollection(row.item.name)
                  ? {
                      name: 'EditCollection',
                      params: {
                        indexName: _vm.indexName,
                        collectionName: row.item.name
                      }
                    }
                  : ''}},[_c('i',{staticClass:"fa fa-pencil-alt"})]),(
                _vm.$store.direct.getters.kuzzle.currentEnvironment
                  .backendMajorVersion !== 1 && !row.item.isRealtime()
              )?_c('b-button',{staticClass:"mx-1",attrs:{"variant":"link","title":"Delete collection","data-cy":("CollectionList-delete--" + (row.item.name))},on:{"click":function($event){return _vm.onDeleteCollectionClicked(row.item)}}},[_c('i',{staticClass:"fa fa-trash"})]):_vm._e()]}}],null,false,3237236698)})]],2):_vm._e(),_c('DeleteCollectionModal',{attrs:{"index":_vm.index,"collection":_vm.collectionToDelete,"modalId":_vm.deleteCollectionModalId},on:{"delete-successful":_vm.onDeleteModalSuccess}}),_c('BulkDeleteCollectionsModal',{attrs:{"index":_vm.index,"collections":_vm.selectedCollections,"modalId":_vm.bulkDeleteCollectionsModalId},on:{"delete-successful":_vm.onDeleteModalSuccess}})],1):_vm._e(),_c('DeleteIndexModal',{ref:_vm.deleteIndexModalId,attrs:{"index":_vm.index,"modalId":_vm.deleteIndexModalId},on:{"confirm-deletion":_vm.onDeleteIndexConfirm,"cancel":_vm.onDeleteIndexCancel}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }